module.exports = {
    monthly_free_minutes:'每月贈送分鐘(僅在訂閱狀態下有效)',
    monthly_free_minutes_tooltip: '此分鐘沒用完可以持續累積，有效直到取消訂閱為止。<br>相較於另外購買的分鐘數，會優先使用此分鐘。',
    additional_seat: '帳號共享用戶',
    additional_seat_tooltip: '邀請使用者共享帳戶，並授予他們對會分鐘數、文字檔、影片和功能的存取權，權限可自訂。',
    monthly_free_ai_credits:'AI 點數<a href="https://www.taption.com/ai-analysis/zh" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">詳細資訊</a>',
    monthly_free_ai_credits_tooltip: '訂閱後每月將自動補滿AI點數，可隨時加購更多點數。<br>「高級訂閱」每月最多補到 {0} 點<br>「大量訂閱」每月最多補到 {1} 點',
    monthly_free_ai_credits_desc:'每月補至{0}點',
    minutes_price:'影片分鐘購買價格',
    minutes_price_tooltip: '另購買分鐘不會因取消訂閱而失效。',
    max_file_length:'上傳影片最大長度',
    max_file_size:'上傳檔案大小限制',
    max_label_number:'影片標籤分類上限',
    automated_transcription: '自動將影音檔轉成文字',
    editing_platform_unlimited: '編輯平台使用無時間限制',
    language_supported: '支援40多種語言',
    highlight_text: '精準時間對照編輯器',
    translate: '50多種語言翻譯',
    translate_basic_tooltip: '翻譯會扣除分鐘',
    translate_premium_tooltip: '翻譯會扣除分鐘',
    translate_corp_tooltip: '翻譯會扣除分鐘',
    personal_dictionary: '個人AI辭典',
    allow_commercial: '可供商業相關之用途',
    mp4_multi_language_export_tooltip:'支援50+語言的雙語字幕，您可以進入編輯頁面後從右上角的『翻譯』選單翻譯此影片來增加更多雙語字幕的選項。',
    mp4_multi_language_export: '雙語字幕崁入影片導出',
    mp4_multi_language_export_basic_tooltip:'未訂閱導出影片會扣除分鐘',
    mp4_audio_export: '上傳錄音導出客製化背景的字幕影片',
    mp4_audio_export_tooltip: '將錄音檔以影片形式導出，可客製化影片背景及將（雙語）字幕嵌入影片。',
    mp4_audio_export_basic_tooltip: '影片背景客製化只開放給訂閱用戶，未訂閱用戶會以黑色背景導出。',
    api_integration:'API客製化串連',
    api_integration_tooltip: '請與我們聯繫help@taption.com來進一步了解詳情',
    api_integration_basic_tooltip: '請與我們聯繫help@taption.com來進一步了解詳情',
    api_integration_premium_tooltip: '請與我們聯繫help@taption.com來進一步了解詳情',
    api_integration_corp_tooltip: '請與我們聯繫help@taption.com來進一步了解詳情',
    custom_payments: '客製化付款、開立紙本發票',
    custom_payments_tooltip: '彈性的付款週期和付款方式，包括轉帳付款，開立紙本統編發票(付款週期必須為年繳）。',
    advanced_training: '進階使用者培訓',
    advanced_training_tooltip: '需要協助讓所有人更了解平台嗎？我們可以提供幫助。',
    high_definition:'原畫質影片',
    high_definition_tooltip: '以原畫質影片編輯和分享',
    mp4_export: '字幕崁入影片導出',
    mp4_export_basic_tooltip: '未訂閱導出影片會扣除分鐘',
    editable_transcript_sharing: '分享給其他用戶編輯',
    dark_mode: '夜間模式',
    video_editing: '用字幕剪輯影片片段',
    video_cutting:'影片剪輯',
    multi_upload:'影片批量上傳',
    invoice_company:'發票顯示公司名稱',
    invoice_taxid:'發票顯示統一編號',
    share_minutes:'用戶共享分鐘',
    share_minutes_tooltip:'加入用戶來共享此帳號的分鐘以及影片',
    permission_users:'針對每個加入用戶提供7種權限管理<a href="https://www.taption.com/collaborate/zh" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">詳細資訊</a>',
    permission_users_tooltip:'對每個用戶的翻譯，上傳，刪除，導出，編輯等權限做限制管理',
    max_char:'每段最大字符',
    max_char_tooltip:'轉譯後的文字依據此數值來分段，<br>並且每段不會超過此字符。',

    direct_link:'使用多種影音檔網址連接直接轉譯(免上傳)',
    direct_link_tooltip:'支援以下：<br>• Facebook影片連結<br>• Instagram影片連結<br>• X影片連結<br>• Tiktok影片連結<br>• 雲端影音URL連結<br>• Dropbox分享連結<br>• One Drive分享連結<br>• Google Drive分享連結<br><br>',
    auto_extent_subtitle:'自動延長每段字幕秒數',
    auto_extent_subtitle_tooltip:'會將每段延長提供的秒數，<br/>這可以避免間隔較短的字幕轉換時的「閃爍」，<br/>讓字幕與字幕間的轉換更為流暢',
    clipped_video:'擷取影音片段',
    clipped_video_tooltip:'從影音檔中擷取片段來辨識文字，更有效地利用分鐘數<br>支援Youtube連結,mp3及mp4類型影音檔',
    custom_format:'客製化格式導出',
    custom_format_tooltip:'當選擇以TXT或PDF形式下載存檔時，可依照個人需求來編排每一段時間，文字，人名及備註的輸出格式。',
    editing_font_size:'調整編輯平台字體大小',
    
    low_cost:'價格親民',
      title_basic:'影片長度計價',
      basic_cost_usd:'$8',
      basic_cost_ntd:'(240 NTD)',
      unit:'美金',
      hour:'小時',
      basic_feature5:'充值<span style="color: #00d1b2;">60分鐘</span>',
      basic_feature1:'自動將影音檔轉成文字',
      basic_feature2:'支援40多種語言',
      basic_feature3:'精準時間對照編輯器',
      basic_feature4:'50多種語言翻譯',
      basic_feature7:'個人AI辭典',
      basic_feature6:'可供商業相關之用途',
      basic_feature8:'雙語字幕崁入影片導出',

      title_premium:'高級訂閱',
      premium_cost_usd:'$12',
      premium_cost_ntd:'(360 NTD)',
      premium_basic_cost_usd:'$6',
      month:'月',
      
      premium_before:'<span class="landing-arrow-left">&#10229;</span>所有『影片長度計價』功能',
      premium_feature1:'每個月贈送<span style="color: #00d1b2;">120分鐘</span> <br><span style="color:#797676;font-size:14px;font-weight: 100;">(可跨月累積，有效至訂閱取消為止)</span>',
      premium_feature1_tooltip:'此分鐘沒用完可以持續累積，有效直到取消訂閱為止。<br>相較於另外購買的分鐘數，會優先使用此分鐘。<br>另購買分鐘不會因取消訂閱而失效。',
      premium_feature2:'如需更多分鐘, 以7.5折優惠價格購買',
      premium_feature3:'字幕崁入影片不扣分鐘，無限制導出',
      premium_feature4:'分享給其他用戶編輯',
      premium_feature5:'錄音檔轉為有字幕的影片檔',
      premium_feature6:'影片剪輯 (字幕自動調整)',

      title_corp:'大量訂閱',
      bulk_cost_usd:'$69',
      bulk_cost_ntd:'(2070 NTD)',
      bulk_basic_cost_usd:'$3',
      bulk_before:'<span class="landing-arrow-left">&#10229;</span>所有『影片長度計價』和『高級訂閱』功能',
       bulk_feature2:'如需更多分鐘, 以3.7折優惠價格購買',
      premium_feature1_bulk:'每個月贈送<span style="color: #00d1b2;">1000分鐘</span><br><span style="color:#797676;font-size:14px;font-weight: 100;">(可跨月累積，有效至訂閱取消為止)</span>',
      bulk_feature1:'提供公司名稱以及統一編號的發票下載',
       bulk_feature3:'加入用戶共享分鐘',
       bulk_feature4:'針對加入用戶提供6種權限管理',

      go_to_price:'了解更多',

      choose_plan:'選擇方案',
      annually:'年繳制',
      monthly:'月繳制',
      annually_save:'年繳省下{0}%的費用',
      annually_deduct:'每年自動扣款${0}'

}